<template>
	<v-card>
		<div class="pagemove" @click="pagemove(1)">
			<h6 class="small">scroll</h6>
			<v-icon>mdi-chevron-down</v-icon>
		</div>
		<v-app-bar app flat color="transparent">
			<router-link to="/" v-slot="{ navigate }">
				<div style="cursor: pointer" v-on:click="navigate">
					<span class="title ml-3 mr-5">
						<span v-show="!$vuetify.breakpoint.xs">Qmonus&nbsp;</span>
						<span class="font-weight-light">Developer Portal</span>
					</span>
				</div>
			</router-link>
			<v-spacer></v-spacer>

			<!-- <router-link to="/faq" v-slot="{ navigate }">
        <v-btn outlined link v-on:click="navigate">
          <v-icon >mdi-help-circle</v-icon>
          <span>FAQ</span>
        </v-btn>
      </router-link>

      <div style="padding:10px;">&nbsp;</div> -->
			<v-btn href="/rss.xml" icon class="mx-4">
				<v-icon size="32px">mdi-rss-box</v-icon>
			</v-btn>

			<router-link to="/contents" v-slot="{ navigate }">
				<v-btn outlined link v-on:click="navigate">
					<v-icon>mdi-text-box</v-icon>
					<span class="ml-1">Documents</span>
				</v-btn>
			</router-link>

			<!-- <div style="padding:10px;">&nbsp;</div>

      <router-link to="/login" v-slot="{ navigate }">
        <v-btn  outlined link v-on:click="navigate">
          <v-icon >mdi-login</v-icon>
          <span>Login</span>
        </v-btn>
      </router-link> -->
		</v-app-bar>

		<!-- Page.1 -->
		<v-row class="page">
			<v-parallax :src="require('../assets/qmonus_bg.jpg')">
				<v-row align="center" justify="center">
					<v-col align="center" cols="12">
						<div v-show="!$vuetify.breakpoint.xs">
							<h2 class="subheading font-weight-thin" red>
								Maximize Your Developer Experience
							</h2>
							<h2 class="subheading font-weight-thin">
								Microservices DevOps Platform as a Service
							</h2>
							<br />
						</div>
						<span
							style="position: absolute; text-indent: 210px; font-size: 22px"
						>
							&reg;
						</span>
						<v-img
							max-width="400"
							height="100"
							:src="require('../assets/qmonus_logo.svg')"
						></v-img>

						<br />
						<v-card-text>click to login</v-card-text>
						<br />

						<v-container fluid>
							<v-row dense align="center" justify="center">
								<v-col cols="3">
									<v-btn
										x-large
										outlined
										block
										href="https://console.sdk-lab.qmonus.net"
									>
										<v-avatar size="32" tile>
											<img src="lab_icon.svg" />
										</v-avatar>
										<span v-show="!$vuetify.breakpoint.xs"> SDK Lab</span>
									</v-btn>
								</v-col>
								<v-col cols="3">
									<v-btn
										x-large
										outlined
										block
										href="https://console.valuestream.qmonus.net"
									>
										<v-avatar size="32" tile>
											<img :src="require('../assets/vs.svg')" />
										</v-avatar>
										<span v-show="!$vuetify.breakpoint.xs">Value Stream</span>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>

						<v-card-text style="color: orange"
							>Currently this Poducts only use for NTT Group</v-card-text
						>
					</v-col>
				</v-row>
			</v-parallax>
		</v-row>

		<!-- video -->
		<v-row class="page">
			<v-parallax :src="require('../assets/qmonus.svg')">
				<v-row align="center" justify="center">
					<v-col align="center" cols="12">
						<h1
							class="display-1 font-weight-thin mb-4"
							style="font-size: 55px !important"
						>
							What is Qmonus?
						</h1>
						<br />
						<p style="max-width: 80%">
							<strong>Qmonus</strong> is a platform that accelerates the
							‘DevOps’ of cloud-native microservices. <br />We consider it to be
							one of the best practices that embody the
							<strong>Cognitive Foundation</strong>,which is one of the
							components of <strong>IOWN</strong> (Innovative Optical & Wireless
							Network).
						</p>
						<br />
						<v-container fluid>
							<v-row dense align="center" justify="center">
								<v-col cols="4">
									<h3>What is <br />'IOWN'</h3>
									<video
										:poster="require('../assets/poster.jpg')"
										controls
										src="https://sandbox-portal.qmonus.ntt.com/01_IOWN.mp4"
										frameborder="0"
										style="border: none; max-width: 60%"
									/>
								</v-col>

								<v-col cols="4">
									<h3>What is <br />'Cognitive Foundation'</h3>
									<video
										:poster="require('../assets/poster.jpg')"
										controls
										src="https://sandbox-portal.qmonus.ntt.com/02_Concept.mp4"
										frameborder="0"
										style="border: none; max-width: 60%"
									/>
								</v-col>

								<v-col cols="4">
									<h3>What is <br />'Qmonus'</h3>
									<video
										:poster="require('../assets/poster.jpg')"
										controls
										src="https://sandbox-portal.qmonus.ntt.com/03_Qmonus.mp4"
										frameborder="0"
										style="border: none; max-width: 60%"
									/>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-parallax>
		</v-row>

		<!-- News -->

		<!-- <v-row class="page">
			<v-parallax :src="require('../assets/qmonus.svg')">
				<v-row align="center" justify="center">
					<v-col align="center" cols="12">
						<h1
							class="display-1 font-weight-thin mb-4"
							style="font-size: 55px !important"
						>
							News
						</h1>
						<br />
						<v-container fluid>
							<v-row dense align="center" justify="center">
								<v-col cols="10">
									<table>
										<thead>
											<tr>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="topic in topics"
												:key="topic.date"
											>
												<td style="padding-right: 30px">
													<v-badge
														v-if="topic.isnew"
														color="orange"
														content="New"
													></v-badge
													>&nbsp;
												</td>
												<td>
													<span
														class="
															font-weight-light
														"
														>{{ topic.date }}</span
													>
												</td>
												<td>
													<span
														class="
															font-weight-light
														"
														>{{
															topic.product
														}}</span
													>
												</td>
												<td>{{ topic.message }}</td>
											</tr>
										</tbody>
									</table>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-parallax>
		</v-row> -->

		<!-- Contact -->

		<!-- <v-row class="page">
			<v-parallax :src="require('../assets/qmonus_bg.jpg')">
				<v-row align="center" justify="center">
					<v-col align="center" cols="12">
						<h1
							class="display-1 font-weight-thin mb-4"
							style="font-size: 55px !important"
						>
							Contact
						</h1>
						<br />
						<h2 style="color: orange">
							Currently this Poducts only use for NTT Group
						</h2>
						<v-container fluid>
							<v-row dense align="center" justify="center">
								<v-col cols="10">
									<table>
										<thead>
											<tr>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th>サンドボックス利用申請</th>
												<td>
													<a
														href="mailto:info&#64;example.com"
														>Qmonusサービス担当（CW)</a
													>
												</td>
											</tr>
											<tr>
												<th>導入相談</th>
												<td>
													<a
														href="mailto:info&#64;example.com"
														>Qmonusサービス担当</a
													>
												</td>
											</tr>

											<tr>
												<th colspan="5">
													<h2 style="color: orange">
														以下は利用者限定
													</h2>
												</th>
											</tr>

											<tr>
												<th>技術問い合わせ</th>
												<td>
													<a
														href="mailto:info&#64;example.com"
														>Qmonusサービス担当</a
													>
												</td>
											</tr>
											<tr>
												<th>不具合申告</th>
												<td>
													<a
														href="mailto:info&#64;example.com"
														>Qmonusサービス担当</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-parallax>
		</v-row> -->

		<!-- Page.3 -->
		<!--
		<v-row class="page">
			<v-parallax :src="require('../assets/sdk.svg')">
				<v-row align="center" justify="center">
					<v-col align="center" cols="12">
						<h1
							class="display-1 font-weight-thin mb-4"
							style="font-size: 55px !important"
						>
							Tools
						</h1>
						<br />

						<v-container fluid>
							<v-row dense align="center" justify="center">
								<v-col cols="3">
									<v-card height="150" outlined disabled>
										<v-card-title
											><v-icon>mdi-cash-usd</v-icon>SDK
											Playground</v-card-title
										>
										<v-card-subtitle
											>Provides an environment that you
											can easily try.</v-card-subtitle
										>
										<v-card-subtitle style="color: red"
											>Sorry, there is no space
											(5/5)</v-card-subtitle
										>
									</v-card>
								</v-col>
								<v-col cols="3">
									<v-card
										height="150"
										outlined
										href="https://github.com/axis-edge/qmonus-plugin-builder/archive/main.zip"
									>
										<v-card-title
											style="text-align: center"
										>
											<v-icon>mdi-download</v-icon
											>Download Plugin-Builder
										</v-card-title>
										<v-card-subtitle
											>Download the offline development
											tool for the SDK
											plugin.</v-card-subtitle
										>
									</v-card>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-parallax>
		</v-row>
		-->
	</v-card>
</template>

<script>
export default {
	props: {
		topics: Array,
	},

	methods: {
		pagemove(index) {
			alert(index);
			this.pageindex += index;
			this.scrollIndex = this.pageindex * window.innerHeight + 64;
			this.$vuetify.goTo(this.scrollIndex);
		},
	},

	data() {
		return {
			moveflg: false,
			pageindex: 0,
			scrollIndex: 0,
		};
	},
};
</script>

<style scoped lang="less">
@keyframes blinkEffect {
  0% {
    color: white;
    font-size: 50px;
  }
  100% {
    color: #555;
    font-size: 40px;
  }
}

.pagemove {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  pointer-events: none;

  .v-icon {
    color: #555;
    font-size: 50px;
    animation: blinkEffect 1s ease infinite;
  }
}

::v-deep .v-parallax {
  width: 100%;
  height: 100% !important;
}

::v-deep .v-image__image--cover {
  background-size: contain !important;
}

::v-deep .v-parallax__image {
  filter: blur(20px) opacity(40%);
}

.v-btn--outlined {
  border-radius: 0;
}

table td,
table th {
  padding: 3px;
}
</style>
