import Vue from 'vue'
import VueRouter from 'vue-router';
import Vuetify from 'vuetify'
import path from 'path-browserify';
import jsyaml from 'js-yaml';

import 'vuetify/dist/vuetify.min.css'


const hljs = require('highlight.js')
require('highlight.js/styles/github-dark.css')

const MarkdownIt = require('markdown-it');
const md = new MarkdownIt({
  html: true,
  linkify: true,
  breaks: true,
  typographer: true,

  highlight(code, lang) {
    if (lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      try {
        code = hljs.highlight(code, { language: language, ignoreIllegals: true }).value;
      } catch (ex) {
        console.warn(ex)
      }
    }

    return `<pre class="hljs"><span style="position:absolute;font-style:italic;right:35px;color:white">${lang}</span><br>` + code + "</pre>";
  }

});



const containerPlugin = require('markdown-it-container')
const emojiPlugin = require('markdown-it-emoji');
const footnotePlugin = require('markdown-it-footnote');
const markPlugin = require('markdown-it-mark');
const deflistPlugin = require('markdown-it-deflist');
const checkboxPlugin = require('markdown-it-checkbox')

md.use(containerPlugin, "tip");

md.use(containerPlugin, "info");
md.use(containerPlugin, "success");
md.use(containerPlugin, "warning");
md.use(containerPlugin, "danger");
md.use(emojiPlugin);
md.use(checkboxPlugin);
md.use(footnotePlugin);
md.use(markPlugin);
md.use(deflistPlugin);

if (process.env.NODE_ENV === "development") {
  Vue.config.devtools = true
  Vue.config.debug = true
  Vue.config.silent = false
  Vue.config.performance = true
  Vue.config.productionTip = false
}

Vue.use(VueRouter)
Vue.use(Vuetify)

import App from './App.vue'
import HomePage from './pages/home.vue';
import ContentPage from './pages/contents.vue';
import FaqPage from './pages/faq.vue';
import DocPage from './pages/docs.vue';
import ErrorPage from './pages/error.vue'

const vuetify = new Vuetify({
  theme: {
    dark: true,
  }
});



let anchorRuler = () => { }


const containerRenderer = (cls, regex) => {
  return (tokens, idx) => {
    let token = tokens[idx];
    let m = token.info.trim().match(regex);
    return `<div class='v-card v-sheet v-sheet--outlined theme--light ${cls.toLowerCase()}' style='padding:8px;margin:8px;'><div class="title">${m && m[1] ? md.utils.escapeHtml(m[1]) : cls}</div>`
  }
}


md.renderer.rules.container_tip_open = containerRenderer("Tip", /^tip\s+(.*)$/)
md.renderer.rules.container_info_open = containerRenderer("Info", /^info\s+(.*)$/)
md.renderer.rules.container_success_open = containerRenderer("Success", /^success\s+(.*)$/)
md.renderer.rules.container_warning_open = containerRenderer("Warning", /^warning\s+(.*)$/)
md.renderer.rules.container_danger_open = containerRenderer("Danger", /^danger\s+(.*)$/)


md.core.ruler.push('anchor', state => {
  const tokens = state.tokens
  for (let idx = 0; idx < tokens.length; idx++) {
    const token = tokens[idx]

    if (token.type !== 'heading_open') {
      continue
    }

    const title = tokens[idx + 1]
      .children
      .filter(token => token.type === 'text' || token.type === 'code_inline')
      .reduce((acc, t) => acc + t.content, '')

    anchorRuler({ token, title, level: Number(token.tag.substr(1)) })
  }

})


let assetsContext = require.context("./docs", true, /\.(jpe?g|png|gif|svg|eot|woff|ttf|svg|woff2|pdf)$/);

let docsContext = require.context("./docs", true, /\.(yam?l|md|html)$/);



let originimage = md.renderer.rules.image;


/**
 * 
 * @param {*} base 
 * @param {*} docSetting 
 */
const documentLoader = (base, docSetting = {}) => {

  //
  return (docSetting.pages || []).map(doc => {
    let docpath = Object.values(doc)[0];


    let content = docsContext(`${path.dirname(base)}/${path.normalize(docpath)}`);

    let toc = [];
    let html = content;


    if (/\.yaml$/.test(docpath)) {

      let loadpath = `${path.dirname(base)}/${path.normalize(docpath)}`;

      // indexファイルをロード
      let pageSetting = jsyaml.load(docsContext(loadpath))

      let pages = documentLoader(loadpath, pageSetting);

      html = content = "";
      pages.forEach(page => {
        html += `<h1 id="${page.title}">${page.title}</h1><br>${page.html}<hr>`
        content += `${content}<br>${page.content}`

        toc.push({
          level: 2,
          text: page.title,
          // toc: page.toc
        })
      })
    }
    else if (/\.md$/.test(docpath)) {

      anchorRuler = ({ token, title, level }) => {
        let id = token.attrGet('id');
        if (id == null) {
          id = `${base}/${title}`;
          token.attrSet('id', id)
        }
        toc.push({
          level: level,
          id: id,
          text: title
        })
      }



      md.renderer.rules.image = (tokens, idx, options, env, self) => {

        let imageToken = tokens.filter(t => t.type == "image");

        if (imageToken[0].attrs == null) return originimage.apply(null, [tokens, idx, options, env, self]);

        let srcAttr = imageToken[0].attrs.filter(atr => atr[0] == "src")
        let href = srcAttr[0][1];
        let assetpath = href;
        if (href && !/^http(s)?/.test(href)) {
          let imagepath = "./" + path.normalize(path.dirname(base) + "/" + path.normalize(path.dirname(docpath) + "/" + path.normalize(href)));
          try {
            assetpath = assetsContext(imagepath);
          }
          catch (ex) {
            console.error(docpath, `renderer.image ${ex.message} >>`, href, "=>", imagepath, "=>", assetpath)
          }
        }
        srcAttr[0][1] = assetpath;


        return `<div class='v-img theme--light' style='cursor:zoom-in;padding:8px;margin:8px;'><img src="${assetpath}" onClick="__imgpreview('${assetpath}')" /></div>`

        // return originimage(tokens, idx, options, env, self);
      }

      html = md.render(content)
    }

    return {
      title: Object.keys(doc)[0],
      routePath: path.basename(docpath),
      path: `/document/${path.normalize(path.dirname(base))}/${path.normalize(docpath)}`.replace(path.extname(docpath), ""),
      // content: content,
      content: content.replace(/(<([^>]+)>)/gi, ''),
      html: html,
      toc: toc
    };
  })
}


let contents = require('./docs/contents.yaml');
let contentSetting = jsyaml.load(contents);


const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "*",
      component: ErrorPage
    },
    {
      path: '/',
      component: HomePage,
      props: {
        topics: [
        ]
      },
      meta: {
        title: "Qmonus Developer Portal",
      }
    },
    {
      path: '/contents',
      component: ContentPage,
      props: {
        contents: contentSetting
      },
      meta: {
        title: "Documents",
        footer: true,
      }
    },
    {
      path: '/faq',
      component: FaqPage,
      props: {
      },
      meta: {
        title: "FAQ",
        footer: true,
      },
    },
  ],
});

Vue.nextTick(() => {

  contentSetting = contentSetting.map(content => {

    if (content.background) {
      if (!/^http(s)?/.test(content.background)) {
        content.background = assetsContext(content.background)
      }
    }

    let documents = (content.documents || []).map(doc => {

      let docpath = doc.path;

      if (docpath && !/^http(s)?/.test(docpath)) {
        doc.path = "/document/" + path.normalize(docpath.split(path.extname(docpath)).join(""))

        // indexファイルをロード
        let pageSetting = jsyaml.load(docsContext(docpath))

        let pages = documentLoader(docpath, pageSetting);



        router.addRoute({
          path: doc.path,
          component: DocPage,
          props: {
            basepath: doc.path,
            title: doc.title,
            description: pageSetting.description,
            pages: pages
          },
          meta: {
            titile: doc.title,
            footer: false
          }
        })

        pages.forEach((p, i) => {

          router.addRoute({
            path: p.path,
            component: DocPage,
            props: {
              basepath: doc.path,
              title: doc.title,
              pages: pages,
              pageindex: i,
            },
            meta: {
              titile: doc.title,
              footer: false
            }
          })
        })

      }

      let hrefpath = doc.href;
      if (hrefpath && !/^http(s)?/.test(hrefpath)) {
        try {
          doc.href = assetsContext(hrefpath).default
        }
        catch (ex) {
          console.error("assets load fail , direct assets load >>", doc.href)
        }
      }

      return doc;
    })

    //
    return {
      subheadings: (content.caption || "").split("\n"),
      titles: (content.title || "").split("\n"),
      subtitles: (content.description || "").split("\n"),
      background: content.background,
      documents: documents
    };

  })

  router.beforeEach((to, from, next) => {
    // ページ遷移前にmeta情報からタイトルを設定
    document.title = to.meta.title || "Qmonus Developer Portal";
    next();
  });
  
  new Vue({
    render: h => h(App),
    router,
    vuetify
  }).$mount('#app')

})
