<template>
  <v-card>
    <v-app-bar app flat color="transparent">
      <router-link to="/"  v-slot="{ navigate }">
        <div style="cursor:pointer;" v-on:click="navigate" >
          <span class="title ml-3 mr-5">Qmonus&nbsp;<span class="font-weight-light">Developer Portal</span></span>        
        </div>
      </router-link>
      <span class="title font-weight-light">/ FAQ</span>
      <v-spacer></v-spacer>
    </v-app-bar>


  </v-card>
</template>

<script>

export default {
  props: {
  },

  computed : {
  },

  methods :{
  }
}
</script>

<style scoped>

::v-deep .v-carousel {
  height: 100vh !important;
}
::v-deep .v-carousel__item {
  height : 100% !important;
}

::v-deep .v-carousel__controls {
  background : transparent;
}

::v-deep .v-image__image {
  filter: blur(20px) opacity(30%);
}

::v-deep .v-card__subtitle {
  text-align: left;
}

::v-deep .popup {
  font-size:12px;
  position: absolute;
  right:10px;
  bottom:3px;
  color : gray;
}

</style>