<template>
	<v-card>
		<v-app-bar app flat color="transparent">
			<router-link to="/" v-slot="{ navigate }">
				<div style="cursor: pointer" v-on:click="navigate">
					<span class="title ml-3 mr-1">
						<span v-show="!$vuetify.breakpoint.xs">Qmonus&nbsp;</span>
						<span class="font-weight-light">Developer Portal /</span></span
					>
				</div>
			</router-link>
			<span class="title font-weight-light ml-1">Documents</span>
			<v-spacer></v-spacer>
		</v-app-bar>

		<v-carousel cycle>
			<v-carousel-item
				v-for="(page, index) in pagesettings"
				:key="index"
				:src="page.background"
			>
				<v-row class="page" align="center" justify="center">
					<v-col class="text-center" cols="10">
						<template v-if="page.subheadings">
							<!-- <h4
								class="subheading font-weight-thin"
								red
								v-bind:key="'head_' + hi"
								v-for="(head, hi) in page.subheadings"
							>
								{{ head }}
							</h4> -->
						</template>
						<br />
						<h1
							class="display-1 font-weight-thin mb-4"
							style="font-size: 55px !important"
							v-bind:key="'title_' + ti"
							v-for="(title, ti) in page.titles"
						>
							{{ title }}
						</h1>
						<br />
						<template v-if="page.subtitles">
							<h3
								class="subheading font-weight-thin"
								red
								v-bind:key="'sub_' + si"
								v-for="(sub, si) in page.subtitles"
							>
								{{ sub }}
							</h3>
						</template>
						<br />
						<v-container fluid v-if="page.documents">
							<v-row dense align="center" justify="center" fill-height>
								<v-col
									cols="5"
									v-for="(act, actin) in page.documents"
									:key="actin"
									fill-height
								>
									<router-link
										v-if="act.path"
										:to="act.path"
										v-slot="{ navigate }"
									>
										<v-card
											height="90"
											outlined
											@click="navigate"
											:disabled="act.disabled"
										>
											<v-card-title
												class="font-weight-light justify-center"
												v-text="act.title"
											></v-card-title>
											<v-card-subtitle
												class="font-weight-light text-caption text-sm-subtitle text-center text-truncate"
												v-text="act.description"
											></v-card-subtitle>
										</v-card>
									</router-link>

									<v-card
										height="90"
										outlined
										v-else
										@click="windowOpen(act)"
										:disabled="act.disabled || act.href == null"
									>
										<v-card-title
											class="font-weight-light justify-center"
											v-text="act.title"
										></v-card-title>
										<v-card-subtitle
											class="font-weight-light text-center text-truncate"
											v-show="!$vuetify.breakpoint.xs"
											v-text="act.description"
										></v-card-subtitle>
										<!-- <div class="popup">{{ act.href? act.href : (act.disabled || act.href==null ) ? 'Under construction':''}}</div> -->
									</v-card>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-carousel-item>
		</v-carousel>
	</v-card>
</template>

<script>
export default {
	props: {
		contents: Array,
	},

	computed: {
		pagesettings() {
			return this.contents || [];
		},
	},

	methods: {
		windowOpen({ href, title }) {
			window.open(href, title);
		},
	},
};
</script>

<style scoped>
::v-deep .v-carousel {
  height: 100vh !important;
}
::v-deep .v-carousel__item {
  height: 100% !important;
}

::v-deep .v-carousel__controls {
  background: transparent;
}

::v-deep .v-image__image {
  filter: blur(20px) opacity(30%);
}

::v-deep .v-card__subtitle {
  text-align: left;
}

/* ::v-deep .popup {
	font-size: 12px;
	position: absolute;
	right: 10px;
	bottom: 3px;
	color: gray;
} */
</style>
