<template>
	<div style="height: 100%; padding-top: 56px; border-radus: 0px">
		<v-app-bar app style="left: 0px">
			<v-app-bar-nav-icon
				v-show="!sidebar"
				v-on:click="sidebar = !sidebar"
			></v-app-bar-nav-icon>

			<router-link to="/contents" v-slot="{ navigate }">
				<div
					style="cursor: pointer"
					v-on:click="navigate"
					v-show="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
				>
					<span class="title ml-3 mr-1"
						>Qmonus&nbsp;<span class="font-weight-light"
							>Documents /
						</span></span
					>
				</div>
			</router-link>
			<router-link :to="basepath" v-slot="{ navigate }">
				<span
					class="title font-weight-light ml-1 mr-1"
					v-on:click="navigate"
					>{{ title }} /</span
				>
			</router-link>
			<span
				class="title font-weight-light ml-1"
				v-if="currentPage"
				v-show="!$vuetify.breakpoint.xs"
				>{{ currentPage.title }}</span
			>
			<v-spacer></v-spacer>
			<input
				class="search-box"
				ref="input"
				aria-label="Search"
				v-model="searchStr"
				:placeholder="'Search in Document'"
				autocomplete="off"
				spellcheck="false"
				@keydown.enter="onSearchEnter"
				@blur="onSearchBlur"
			/>
		</v-app-bar>

		<v-navigation-drawer clipped app v-model="sidebar">
			<v-app-bar two-line dense fixed flat>
				<router-link to="/contents" v-slot="{ navigate }">
					<v-toolbar-title v-on:click="navigate" class="mr-4">
						<v-icon dark>mdi-text-box</v-icon>
					</v-toolbar-title>
				</router-link>

				<router-link :to="basepath" v-slot="{ navigate }">
					<v-toolbar-title v-on:click="navigate"
						>{{ title }}
					</v-toolbar-title>
				</router-link>
			</v-app-bar>

			<v-list dense nav>
				<v-subheader></v-subheader>
				<v-list-item-group :value="pageindex">
					<router-link
						v-for="(page, i) in pages"
						:to="page.path"
						:key="i"
						v-slot="{ navigate }"
					>
						<v-list-item v-on:click="navigate">
							<v-list-item-content>
								<v-list-item-title>{{
									page.title
								}}</v-list-item-title>

								<v-list v-if="i == pageindex">
									<template v-for="(toc, ti) in page.toc">
										<v-list-item
											:too="toc.id"
											@click="scrollTo(toc)"
											v-if="toc.level == 2"
											:key="ti"
										>
											<v-list-item-title>{{
												toc.text
											}}</v-list-item-title>
										</v-list-item>
									</template>
								</v-list>
							</v-list-item-content>
						</v-list-item>
					</router-link>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<v-card
			style="height: 100%"
			:style="sidebar ? 'left:254px; width:calc( 100% - 254px );' : ''"
			light
		>
			<!-- <template v-if="suggestions">
				<div style="padding: 20px">
					<span class="text-h4">'{{ searchQuery }}' 検索結果</span>
					<div class="alert" v-if="suggestions.length <= 0">
						見つかりません
					</div>
					<div class="alert" v-for="(s, i) in suggestions" :key="i">
						<li>
							<h3>{{ pageRefs[s.ref].title }}</h3>
							<div>{{ s.ref }}</div>
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr
											v-for="(key, i) in Object.keys(
												s.matchData.metadata
											)"
											:key="key"
											@click="moveTo(s.ref, key)"
										>
											<th>{{ i }}</th>
											<td>
												<span
													v-html="
														convertSearchData(key)
													"
												></span>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</li>
					</div>
				</div>
			</template> -->

			<template v-if="currentPage == null">
				<div style="position: absolute; padding-left: 50px">
					<div class="text-h2 mt-8 transition-swing">
						{{ title }}
					</div>
					<div class="mt-8 transition-swing">
						{{ description }}
					</div>
				</div>

				<v-img
					:src="require('../assets/qmonus_bg.jpg')"
					style="
						position: absolute;
						filter: blur(5px) opacity(10%);
						max-height: 100%;
					"
				>
				</v-img>
			</template>

			<div style="padding: 20px; padding-bottom: 120px">
				<div class="currentPage" v-html="currentContent"></div>
				<div
					v-if="nextpage"
					style="position: absolute; bottom: 10px; right: 10px"
				>
					{{ nextpage.title }}
					<v-btn
						fab
						small
						id="prevBtn"
						v-on:click="$router.push(nextpage.path)"
					>
						<v-icon dark>mdi-chevron-right</v-icon>
					</v-btn>
				</div>

				<div
					v-if="prevpage"
					style="position: absolute; bottom: 10px; left: 10px"
				>
					<v-btn
						fab
						small
						id="nextBtn"
						v-on:click="$router.push(prevpage.path)"
					>
						<v-icon dark>mdi-chevron-left</v-icon>
					</v-btn>
					{{ prevpage.title }}
				</div>
			</div>
		</v-card>

		<template>
			<v-dialog
				v-model="imageview"
				style="width: 90%"
				overlay-color="white"
				overlay-opacity="1.0"
			>
				<v-img
					:src="imagesrc"
					style="cursor: zoom-out"
					@click.stop="imageview = false"
				/>
			</v-dialog>
		</template>
		<template>
			<v-bottom-sheet
				v-model="dialog"
				hide-overlay
				scrollable
				style="bottom: 0px"
				light
			>
				<v-card>
					<v-card-title>'{{ searchQuery }}' 検索結果 </v-card-title>
					<v-divider></v-divider>
					<v-card-text style="height: 300px">
						<div
							v-if="
								suggestions == null || suggestions.length <= 0
							"
						>
							見つかりません
						</div>

						<v-expansion-panels>
							<v-expansion-panel
								v-for="(s, i) in suggestions"
								:key="i"
								@click="moveTo(s.ref)"
							>
								<v-expansion-panel-header>
									{{ pageRefs[s.ref].title }}
									<!-- <small>{{ s.ref }}</small>
									<small>{{ s.score }}</small> -->
									<v-badge
										left
										offset-x="50"
										:content="
											Object.keys(s.matchData.metadata)
												.length
										"
									></v-badge>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-simple-table>
										<template v-slot:default>
											<tbody>
												<tr
													v-for="(
														key, i
													) in Object.keys(
														s.matchData.metadata
													)"
													:key="key"
													@click="moveTo(s.ref, key)"
												>
													<th>{{ i + 1 }}</th>
													<td>
														<span
															v-html="
																convertSearchData(
																	key
																)
															"
														></span>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card-text>
				</v-card>
			</v-bottom-sheet>
		</template>
	</div>
</template>


<style scoped lang="stylus">
$textColor = rgba(255, 255, 255, 1);
$borderColor = rgba(150, 150, 150, 1);
$accentColor = orange;

input.search-box {
	display: inline-block;
	position: relative;
	margin-right: 1rem;
	cursor: text;
	width: 10rem;
	height: 2rem;
	color: lighten($textColor, 25%);
	display: inline-block;
	border: 1px solid darken($borderColor, 10%);
	border-radius: 2px;
	font-size: 0.9rem;
	line-height: 2rem;
	padding: 0 0.5rem 0 0.5rem;
	outline: none;
	transition: all 0.2s ease;
	background-size: 1rem;

	&:focus {
		cursor: auto;
		border-color: $accentColor;
		width: 30%;
	}
}
</style>


<style lang="less" scoped>
::v-deep .v-main__wrap {
	left: 254px;
}

::v-deep table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	margin-bottom: 20px;
}
::v-deep table th,
::v-deep table td {
	border: 0.5px solid gray;
	padding-left: 5px;
	padding-right: 5px;
}

::v-deep hr {
	margin-top: 20px;
	margin-bottom: 20px;
}

::v-deep h1 {
	padding-top: 20px;
	padding-bottom: 20px;
}

::v-deep h2 {
	padding-top: 20px;
	padding-bottom: 10px;
}

::v-deep h3 {
	padding-top: 10px;
	padding-bottom: 5px;
}

::v-deep li {
	padding-top: 5px;
	padding-bottom: 5px;
}

// ::v-deep pre {
// 	background: lightgray;

// 	code {
// 		background: none !important;
// 	}
// }

::v-deep blockquote {
	position: relative;
	padding: 10px 10px 10px 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	box-sizing: border-box;
	font-style: italic;
	color: #777777;
	border-left: 4px solid #9dd4ff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
}

::v-deep img {
	display: block;
	max-width: 80%;
	max-height: 350px;
	padding: 5px;
}

::v-deep .highlight {
	background: #ffae10 !important;
	color: black !important;
}
</style>

<script>
import scrollIntoView from "scroll-into-view-if-needed";

const lunr = require("lunr");
require("lunr-languages/lunr.stemmer.support.js")(lunr);
require("lunr-languages/tinyseg.js")(lunr);
require("lunr-languages/lunr.ja.js")(lunr);
require("lunr-languages/lunr.multi.js")(lunr);

window.__scrollTo = (ref) => {
	let target = document.getElementById(ref);
	if (target) {
		scrollIntoView(target, {
			behavior: "smooth",
			block: "center",
			scrollMode: "always" /*'if-needed',*/,
		});
	}
};

const getTextNodes = (pattern, context) => {
	const xPathResult = document.evaluate(
		"//text()", // xpathExpression
		context, // contextNode
		null, // namespaceResolver
		XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, // resultType
		null // result xPath
	);
	const result = [];
	let { snapshotLength } = xPathResult;

	while (snapshotLength--) {
		let ele = xPathResult.snapshotItem(snapshotLength);
		if (
			ele.textContent &&
			ele.textContent.toLowerCase().indexOf(pattern) >= 0
		) {
			result.unshift(ele);
		}
	}
	return result;
};

export default {
	props: {
		title: String,
		basepath: String,
		description: String,
		pages: Array,
		pageindex: Number,
	},

	computed: {
		nextpage() {
			if (this.pageindex == null) return this.pages[0];
			if (this.pageindex > this.pages.length - 1) return null;
			return this.pages[this.pageindex + 1];
		},

		prevpage() {
			if (this.pageindex <= 0) return null;
			return this.pages[this.pageindex - 1];
		},

		currentPage() {
			return this.pageRefs[this.$route.path];
		},

		currentContent() {
			if (this.currentPage != null) {
				// <titile>タグを設定
				document.title = this.currentPage.title;

				// <meta property="og:title"> を設定
				if(!document.querySelector('meta[property="og:title"]')){
					const newMetaTag = document.createElement('meta');
					newMetaTag.setAttribute('property', 'og:title');
					newMetaTag.setAttribute('content', this.currentPage.title);
					// DOM に追加
					document.head.appendChild(newMetaTag);
				}

				
				
				let tocContent = this.currentPage.toc
					.filter((toc) => toc.level == 2)
					.map(
						(toc) => `
			  <li :too="toc.id" :ref="toc.text" onClick="__scrollTo('${toc.id}')" >
			    <a>${toc.text}</a>
			  </li>
			`
					)
					.join("");
					
				return this.currentPage.html.split("[[toc]]").join(tocContent);
			}
			return null;
		},

		pageRefs() {
			let refs = {};
			let pages = this.pages || [];
			pages.forEach((p) => {
				refs[p.path] = p;
			});
			return refs;
		},

		matchQuery() {
			let pages = this.pages;
			return lunr(function () {
				this.ref("path");
				this.field("content", { boost: 10 });
				this.use(lunr.multiLanguage("ja", "en"));
				// this.use(lunr.ja)

				pages.forEach((p) => {
					this.add(p, this);
				});
			});
		},
	},

	methods: {
		convertSearchData(data) {
			return data
				.split(new RegExp(this.searchStr, "i"))
				.join("<span class='highlight'>" + this.searchStr + "</span>");
		},

		onSearchBlur() {
			this.doSearch();
		},

		onSearchEnter(event) {
			if (event.keyCode !== 13) return;
			this.doSearch();
		},

		doSearch() {
			if (this.highlightElement) {
				let len = this.highlightElement.length;
				while (len--)
					this.highlightElement[len].parentElement.classList.remove(
						"highlight"
					);
				this.highlightElement = null;
			}

			if (this.searchStr == "") {
				this.$refs.input.blur();
				this.suggestions = null;
				return;
			}

			const query = this.searchStr.trim();
			if (!query || query.length < 2) {
				return;
			}

			this.$nextTick(() => {
				this.$refs.input.blur();

				let suggestions = this.matchQuery.search("*" + query + "*", {
					expand: true,
				});

				console.error("suggestions", suggestions);

				this.suggestions = suggestions;

				this.searchQuery = query;
				this.dialog = true;
			});
		},

		moveTo(ref, text) {
			const searchText = (text) => {
				this.$nextTick(() => {
					let root = this.$el.getElementsByClassName("currentPage");
					let nodes = getTextNodes(text, root[0]);
					let len = nodes.length;
					console.error(text, root, "=>", nodes);
					let ele = nodes[len - 1].parentElement;

					while (len--)
						nodes[len].parentElement.classList.add("highlight");

					ele.classList.add("highlight");
					this.highlightElement = nodes;

					scrollIntoView(ele, {
						// behavior: "smooth",
						block: "center",
						scrollMode: "always" /*'if-needed',*/,
					});
				});
			};

			// this.suggestions = null;
			if (this.$route.path != ref)
				return this.$router.push(ref, () => {
					this.moveTo(ref, text);

					if (text) searchText(text);
				});
			if (text) searchText(text);
		},

		scrollTo(toc) {
			window.__scrollTo(toc.id || toc.text);
			// let target = document.getElementById( toc.id )
			// scrollIntoView( target , {
			//     behavior   : "smooth",
			//     block      : 'center',
			//     scrollMode : 'always', /*'if-needed',*/
			// });
		},
	},

	mounted() {
		let self = this;
		window.__imgpreview = (src) => {
			self.$set(self, "imagesrc", src);
			self.imageview = true;
		};
	},

	data() {
		return {
			sidebar: true,

			searchStr: "",
			searchQuery: null,
			suggestions: null,
			dialog: false,

			imageview: false,
			imagesrc: null,

			// highlightElement
		};
	},
};
</script>