<template>
	<v-app id="app">
		<v-main>
			<v-container fluid>
				<router-view></router-view>
			</v-container>
		</v-main>

		<v-footer v-show="$route.meta.footer != false" padless>
			<v-container class="text-center">
				<br />
				<p>
					<img :src="require('./assets/qmonus.svg')" width="55px" />
				</p>
				<p>
					<img
						:src="require('./assets/qmonus_logo-inverse.png')"
						width="55px"
					/>
				</p>
				<br />
				<p>
					<router-link to="/" v-slot="{ navigate }">
						<v-btn v-on:click="navigate" icon class="mx-4">
							<v-icon size="32px">mdi-home</v-icon>
						</v-btn>
					</router-link>

					<!-- <router-link to="/signup" v-slot="{ navigate }">
              <v-btn v-on:click="navigate" icon class="mx-4">
                <v-icon size="32px">mdi-account-plus</v-icon>
              </v-btn>
            </router-link> -->

					<v-btn
						href="mailto:qmonus-support@ntt.com"
						icon
						class="mx-4"
					>
						<v-icon size="32px">mdi-email</v-icon>
					</v-btn>
				</p>
				<br />
				<p>
					<v-card-text class="white--text pt-0">
						A place (field) that serves as a means (tool) that
						continues to provide the highest performance for the
						user at the same time, but also serves as a store and a
						share. It is a brand that continues to change toward the
						future, changing shapes and multiplying while responding
						to changing environments.
						<br />
						- Qmonus -
						<br />
					</v-card-text>
				</p>

				<v-divider></v-divider>
				<v-card-text class="white--text">
					Copyright© 2014-{{ new Date().getFullYear() }} NTT
					Communications
				</v-card-text>
			</v-container>
		</v-footer>
	</v-app>
</template>

<script>
export default {
	watch: {
		$route() {
			this.$nextTick(() => {
				this.$vuetify.goTo(0);
			});
		},
	},
};
</script>

<style >
html * {
	font-family: "Roboto";
}
body {
	background: black;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.v-main {
	padding: 0px !important;
}

.v-main .container--fluid {
	padding: 0px !important;
	height: 100%;
}

.search-box input {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	border-radius: 2px;
	width: none !important;
}

.search-box input:focus {
	border-color: orange;
	width: 30vw;
}

.page {
	height: 100vh !important;
	width: 100vw;
}

.v-card {
	padding: 0px;
	border-radius: 0px;
}

.v-btn__content * {
	text-transform: none;
}
</style>
